<template>
  <div>
    <Form label-position="top">
      <Row :gutter="8">
        <i-col span="24">
          <FormItem label="合同名称">
            <Input  size="small" v-model="model.name" placeholder="请填写合同名称" />
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem label="设置合同客户">
            <i-select  size="small" v-model="model.advertiserId">
              <i-option
                v-for="advertiser in advertisers"
                :key="advertiser.advertiserId"
                :value="advertiser.advertiserId"
                >{{ advertiser.advertiserName }}</i-option
              >
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="24" v-if="brandList.length">
          <FormItem label="报备品牌">
            <tag v-for="brand in brandList" :key="brand.id" color="blue">
              {{ brand.name }} ({{ brand.industryName }})
            </tag>
          </FormItem>
        </i-col>
         <i-col span="24">
          <FormItem label="设置发布类型">
            <i-select  size="small" v-model="model.contractType">
              <i-option
                v-for="type in contractTypes"
                :key="type.id"
                :value="type.id"
                >{{ type.name }}</i-option
              >
            </i-select>
          </FormItem>
        </i-col>
          <i-col span="24">
          <FormItem label="标记">

              <Checkbox v-model="model.Promotion">促销</Checkbox>
          </FormItem>
        </i-col>
      </Row>

      <FormItem class="text-right">
        <Button
          type="success"
          class="m-l-8"
          size="small"
          v-show="!isSubmit"
          :loading="loading"
          @click="handleCreateCraft"
          >创建合同</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { getBrandList } from '@/api/crm/brand'
import { createDraft } from '@/api/scp/contract'
import { getOrderAdvertiser } from '@/api/order/order'
import { getcategorylist } from '@/api/scp/scpbusiness'

export default {
  data () {
    return {
      model: { name: '', advertiserId: 0, contractType: 0, Promotion: false },
      advertisers: [],
      contractTypes: [],
      brandList: [],
      loading: false,
      contractType: this.$store.getters.token.publisherSetting.contractType // 创建合同类型
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      getOrderAdvertiser({
        userId: this.$store.getters.token.userInfo.userId,
        status: 1
      }).then((res) => {
        this.advertisers = res
      })
      getcategorylist({}).then(res => {
        this.contractTypes = res
      })
    },
    handleCreateCraft () {
      this.loading = true

      const craft = {
        createdByAdmin: false,
        name: this.model.name,
        advertiserId: this.model.advertiserId,
        subjectType: this.contractType === 1 ? 1 : 3, // 1广告主<->媒介主合同 3广告主<->代理商合同
        salePolicy: this.model.Promotion ? 2 : 1,
        contractCategory: this.model.contractType
      }
      createDraft(craft).then((res) => {
        this.loading = false
        if (res && !res.errcode) {
          this.model.advertiserId = 0
          this.model.name = ''
          this.$Notice.success({ desc: '合同创建成功！' })

          this.$emit('on-create', res)
        }
      })
    }
  },
  computed: {
    isSubmit () {
      return (
        this.model.advertiserId === undefined || this.model.advertiserId === 0 || this.model.contractType === 0
      )
    }
  },
  watch: {
    'model.advertiserId' (val) {
      if (this.model.advertiserId && this.model.advertiserId !== 0) {
        getBrandList({ advertiserId: val, group: true, status: '2,3,5' }).then((res) => {
          this.brandList = res
        })
      } else {
        this.brandList = []
      }
    }
  }
}
</script>
